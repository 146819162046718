import * as GenericRedux from 'generic/genericRedux'
import * as Redux from 'redux'
import { injectReducer } from 'store/index'
import './_styles.scss'
import { AnalyzeSourceContentComponentClass } from './analyze'
import * as Constants from './constants'

export const AnalyzeSourceContentComponent = GenericRedux.createConnect(
  AnalyzeSourceContentComponentClass,
  Constants.REDUX_STORE_HOME,
)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(
      Constants.REDUX_STORE_HOME,
      {url: ''},
    ),
  }),
)
