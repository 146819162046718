import { DropdownOption } from 'components/dropdown/component'
import * as Flash from 'components/flash'
import { TRexIcon } from 'components/icons/t-rex'
import { TRexLargeIcon } from 'components/icons/t-rex-large'
import * as Validation from 'components/validation/validations'
import { RasaContext } from 'context'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as GA from 'google-analytics'
import React from 'react'
import * as GenerativeAI from 'shared_server_client/types/generative_ai'
import './_styles.scss'
import { PaperPlaneIcon } from "../../icons/paper-plane";
import { streamChatResponse, streamSourceResponse } from './data'
import { Loading } from 'components/loading'
import * as Constants from './constants'
import { DEFAULT_PROMPTS, FETCHING_SOURCE, Prompt } from './constants'
import { isTruthy, tryParseJSON } from 'generic/utility'
import { Badge } from 'reactstrap'
import { SubscriptionTypes } from 'shared_server_client/constants'
import { Dataset } from 'generic/dataset'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { trim } from 'lodash'

declare const RASA_IMAGE_API_ENDPOINT: string
const ModelTypeOptions: DropdownOption[] = [
  { description: 'default', key: '' },
  { description: 'mistral-tiny', key: 'mistral|mistral-tiny' },
  { description: 'mistral-small', key: 'mistral|mistral-small' },
  { description: 'openai-3.5', key: 'openai|gpt-3.5-turbo' },
  { description: 'openai-4.0', key: 'openai|gpt-4-1106-preview' },
]

interface State {
  aiChatSessions: any[],
  aiConfirmationType?: Constants.AIConfirmationType,
  activeCommunity: any,
  sources: any
  categories: GenerativeAI.Keyword[],
  chatInputPlaceholder: string,
  community: string,
  confirm: boolean,
  error?: string,
  found: boolean,
  input: string,
  isChatHistoryLoading: boolean,
  isLoading: boolean,
  message: string,
  newPrompts: GenerativeAI.ChatPrompt[],
  topics: string,
  sessionId: string,
  summary: string,
  showDefaultPrompt: boolean,
  showStreamLoader: boolean,
  streamMessage: string,
  suggestedSources: any,
  model: DropdownOption,
  prompt: string,
  prompts: GenerativeAI.ChatPrompt[],
  aiJsonResponse: string,
  pickStreamKey: boolean,
  currentStreamKey: string,
  showConfirmationPrompts: boolean,
  showsummaryStream: boolean,
  showsuggestedSourcesStream: boolean,
  showpromptStream: boolean,
  getSource: boolean
}

const newState: State = {
  aiChatSessions: [],
  aiConfirmationType: null,
  activeCommunity: {},
  sources: [],
  categories: [],
  chatInputPlaceholder: '',
  community: '',
  confirm: false,
  found: true,
  isChatHistoryLoading: false,
  isLoading: false,
  input: '',
  message: '',
  newPrompts: [],
  topics: '',
  sessionId: '',
  summary: '',
  aiJsonResponse: '',
  showDefaultPrompt: true,
  showStreamLoader: false,
  streamMessage: '',
  suggestedSources: [],
  model: ModelTypeOptions[0],
  prompt: '',
  prompts: [],
  pickStreamKey: false,
  currentStreamKey: '',
  showConfirmationPrompts: false,
  showsummaryStream: true,
  showsuggestedSourcesStream: true,
  showpromptStream: true,
  getSource: true
}

export class AnalyzeSourceContentComponentClass extends RasaReactComponent<any, State> {
  public chatEndRef;
  public chatInputRef;
  public static contextType = RasaContext;
  constructor(props: any) {
    super(props, 'communitySource', newState)
    this.sendMessage = this.sendMessage.bind(this)
    this.handleOnEnter = this.handleOnEnter.bind(this)
    this.chatEndRef = React.createRef()
    this.chatInputRef = React.createRef()
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        activeCommunity,
        community: activeCommunity.communityId,
      }, () => {
        this.getChatSessions()
        this.getSources()
      })
    })
  }

  public render = () => {
    return (
      <div className="rex-wrapper">
        <div className="rex-wrapper-left">
          <div className="rex-chat-sessions">
            <div className='rex-chat-session-heading'>
              Previous Chats
              {this.state.prompts.length > 0 ?
              <div className="rex-new-chat-icon" onClick={this.clearChat}><i className="fas fa-edit" /></div> : null}
            </div>
            <div>
              {this.state.aiChatSessions.length ? this.state.aiChatSessions.map((session, index) => {
                return (
                  <div className={`rex-chat-session-item ${this.state.sessionId === session.session_id ? 'active' : ''}`} key={index} onClick={() => this.getChatHistory(session.session_id)}>
                    {session.title}
                  </div>
                )
              }): <div className="rex-chat-session-item">No chat history found</div>}
            </div>
          </div>
        </div>
        <div className="rex-wrapper-right">
          {this.state.isChatHistoryLoading ? <Loading size="16"></Loading> :
          <div>
            <div className="rex-wrapper_scroll rex-wrapper-size" ref={this.chatEndRef}>
              <h1 className="rex-wrapper_heading"><TRexLargeIcon width="200" height="100" /></h1>
              {this.state.showDefaultPrompt && <h1 className="rex-wrapper_heading">Try asking me questions like:</h1>}
              <div className="rex-wrapper_list-msg">
                {!this.state.getSource && this.state.showDefaultPrompt && this.state.prompts.length === 0 ? DEFAULT_PROMPTS.map((prompt, index) => {
                  return (
                    <div key={index} className='rex-wrapper-chat-item'>
                      <button onClick={() => this.executeDefaultPrompt(prompt)}
                              className="rex-wrapper_btn-list">{prompt.content}</button>
                    </div>
                  )
                }) : null}
                {
                  this.state.prompts.map((prompt, index) => {
                    return (
                      <div key={index} className='rex-wrapper-chat-item'>
                        {prompt.role === Constants.PROMPT_ROLE.USER ? <i className="rex-wrapper_img-list fa-user fa"></i> :
                          <i className="rex-wrapper_img-list"><TRexIcon svgwidth="20" svgheight="20"/></i>}
                        {this.buildPrompt(prompt)}
                      </div>
                    )
                  })
                }
                {this.state.sources.length > 0 ?
                    <div className='rex-wrapper-chat-item rex-chat-loader'>
                      <i className="rex-wrapper_img-list">
                        <TRexIcon svgwidth="20" svgheight="20"/>
                      </i>
                      {this.buildSourcesPrompt(this.state)}
                    </div> : null}
                {this.state.summary && this.state.showsummaryStream ? <div className='rex-wrapper-chat-item'>
                  <i className="rex-wrapper_img-list">
                    <TRexIcon svgwidth="20" svgheight="20"/>
                  </i>
                  {this.buildSummaryPrompt(this.state)}</div>: null}
                {this.state.suggestedSources.length && this.state.showsuggestedSourcesStream ? <div className='rex-wrapper-chat-item'>
                  <i className="rex-wrapper_img-list">
                    <TRexIcon svgwidth="20" svgheight="20"/>
                  </i>
                  {this.buildSuggestedSourcesPrompt(this.state)}</div>: null}
                {this.state.prompt ?
                  <div className='rex-wrapper-chat-item'>
                    <i className="rex-wrapper_img-list">
                      <TRexIcon svgwidth="20" svgheight="20"/>
                    </i>
                    <div className="rex-wrapper_msg-list">
                      <p>
                        {this.state.prompt}
                      </p>
                      {this.state.showConfirmationPrompts ?
                      <div>
                        <div className='rex-wrapper-chat-item'>
                          <button onClick={this.suggestContent} className="rex-btn">Yes</button>
                        </div>
                        <div className='rex-wrapper-chat-item'>
                          <button onClick={this.exploreMore}
                                  className="rex-btn">{Constants.EXPLORE_MORE_PROMPT.content}</button>
                        </div>
                      </div> : null}
                    </div>
                  </div> : null}
              </div>
            </div>
            {this.state.showStreamLoader ?
              <div className='rex-wrapper-chat-item rex-chat-loader'>
                <i className="rex-wrapper_img-list">
                  <TRexIcon svgwidth="20" svgheight="20"/>
                </i>
                <div className="rex-wrapper_msg-list">
                  <p>
                    <Loading size="16"></Loading>
                  </p>
                </div>
              </div> : null}
            <div className="rex-wrapper_form-wrapper">
              <form action="#">
                <input type="text" ref={this.chatInputRef} onKeyDown={this.handleOnEnter} value={this.state.message}
                      onChange={(e) => this.setState({message: e.target.value})}
                      placeholder={this.state.chatInputPlaceholder} className="rex-wrapper_form-input"/>
                <button className="rex-wrapper_form-btn-submit">
                  <PaperPlaneIcon onClick={(e) => {
                    e.preventDefault()
                    this.sendMessage()
                  }}/>
                </button>
              </form>
            </div>
          </div>}
        </div>

      </div>
    )
  }

  private buildPrompt = (prompt) => {
    const promptJson = tryParseJSON(prompt.content)
    if (promptJson.summary) {
      return this.buildSummaryPrompt(promptJson)
    } else if (promptJson.sources && promptJson.sources.length > 0) {
      return this.buildSourcesPrompt(promptJson)
    } else if (promptJson.suggestedSources && promptJson.suggestedSources.length > 0) {
      return this.buildSuggestedSourcesPrompt(promptJson)
    } else {
      return this.buildMessagePrompt(prompt)
    }
  }

  private buildSuggestedSourcesPrompt = (prompt) => {
    return <div>
      <div className="rex-wrapper_msg-list">
        <h3>Do you want me to add {prompt.suggestedSources.length > 1 ? 'these Sources' : 'this Source'} to your Sources List?</h3>
        <div className='rex-wrapper-article-list'>
          {prompt.suggestedSources.map((Source) => this.renderSources(Source))}
        </div>
      </div>
    </div>
  }

  private buildMessagePrompt = (prompt) => {
    prompt.content = prompt.content.includes(DEFAULT_PROMPTS[0].content) || prompt.content.includes(DEFAULT_PROMPTS[1].content) ?
      FETCHING_SOURCE : prompt.content
    return <div className="rex-wrapper_msg-list">
      <p dangerouslySetInnerHTML={{__html: this.convertTextToLinks(prompt.content)}}></p>
    </div>
  }

  private buildSummaryPrompt = (prompt) => {
    if (this.state.getSource) {
      return
    }
    return <div className="rex-wrapper_msg-list">
      <p>
        <div>
          <div className="summary">
            <h2>Rex's Summary</h2>
            <span>{prompt.summary}</span>
          </div>
          {prompt.topics && prompt.topics.length > 0 &&
            <div>
              <div className="summary">
                <h3>Topics</h3>
                <span>Topics related to this URL</span>
              </div>
              <div className="keywords">
                {prompt.topics.split(',').map((k: string, topicIndex: number) => (
                  <Badge className="keyword" key={`keyword-${topicIndex}`}>
                    <span>{k}</span>
                  </Badge>))}
              </div>
            </div>}
        </div>
      </p>
    </div>
  }

  private buildSourcesPrompt = (prompt) => {
    return <div>
      <div className="rex-wrapper_msg-list">
      <h3>Here are some Sources you can add to your next newsletter.</h3>
      <div className='rex-wrapper-article-list'>
        {prompt.sources.map((source) => {
          return this.renderSources(source)
        })}
        <div className='rex-wrapper-chat-item'>
          <button onClick={this.suggestMoreContent} className="rex-btn">Show More</button>
          {prompt.sources.length > 1 ?
          <button onClick={() => {
              this.logChatHistory(Constants.ADD_ALL_SOURCES_PROMPT, Constants.PROMPT_ROLE.USER)
              this.moveContentToSource(prompt.sources)
            }}
            className="rex-btn">Add All to Sources</button> : null}
        </div>
      </div>
    </div>
  </div>
  }

  private renderSources = (sources) => {
    return <div className='rex-wrapper-article-item'>
      <div className='rex-wrapper-article-info'>
        <div className='rex-wrapper-article-title'>
          <a href={sources.source_url} target='_blank'>{sources.name}</a>
        </div>
        <div className='rex-wrapper-article-description'>
          {sources.site_name ? `Source: ${sources.site_name}` : ''}
        </div>
        <div className='rex-wrapper-article-add'>
          <button className='rex-btn' onClick={() => {
            this.logChatHistory(`Add Source to Sources: "${sources.name}"`, Constants.PROMPT_ROLE.USER)
            this.moveContentToSource(sources)
          }}>Add to source</button>
        </div>
      </div>
    </div>
  }

  private suggestContent = () => {
    if (this.state.summary) {
      this.setState({
        showStreamLoader: true,
        showConfirmationPrompts: false,
      })
      const payload = {
        topics: this.state.topics,
        summary: this.state.summary,
        session_id: this.state.sessionId,
      }
      streamSourceResponse(this.state.community, payload, this.updateSourcesResponse)
    } else {
      this.streamText(Constants.REX_COULD_NOT_FIND_MSG)
    }
  }

  private suggestMoreContent = () => {
    this.setState({
      showStreamLoader: true,
    })
    this.logChatHistory(Constants.SHOW_MORE_SOURCES_PROMPT, Constants.PROMPT_ROLE.USER).then(() => {
      const payload = {
        session_id: this.state.sessionId,
      }
      streamSourceResponse(this.state.community, payload, this.updateSourcesResponse, true)
    }).catch(() => {
      this.setState({
        showStreamLoader: false,
      }, () => {
        this.streamText(Constants.REX_COULD_NOT_FIND_MSG)
      })

    })
  }

  private exploreMore = () => {
    this.setState({
      prompt: '',
      prompts: [
        ...this.state.prompts,
        {
          role: Constants.PROMPT_ROLE.ASSISTANT,
          content: `${this.state.prompt}`,
        }
      ],
    }, () => {
      this.scrollToBottom()
    })
    this.executeDefaultPrompt(Constants.EXPLORE_MORE_PROMPT)
  }

  private scrollToBottom = () => {
    this.chatEndRef.current.scrollTop = this.chatEndRef.current.scrollHeight;
  }

  protected validInput(): boolean {
    return Validation.validateForgivingUrl(this.state.input).valid || Validation.validateDomain(this.state.input).valid
  }

  protected handleOnEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.sendMessage()
    }
  }

  protected sendMessage = (message: string = "") => {
    if(message.includes(DEFAULT_PROMPTS[0].content) || message.includes(DEFAULT_PROMPTS[0].content)) {
      this.setState({
        getSource: true
      })
    } else {
      this.setState({
        getSource: false
      })
    }
    this.context.store.dispatch(GA.sendGa4Event(this.ga4Event(GA.EventNames.TRexQuestion)))
    this.setState({
      newPrompts: [
        ...this.state.newPrompts,
        {
          role: Constants.PROMPT_ROLE.USER,
          content: message ? message : this.state.message,
        }
      ],
      prompts:
        [
          ...this.state.prompts,
          {
            role: Constants.PROMPT_ROLE.USER,
            content: message ? message : this.state.message,
          }
        ],
      prompt: '',
      showConfirmationPrompts: false,
      message: '',
      showStreamLoader: true,
      showDefaultPrompt: false,
      chatInputPlaceholder: '',
    }, () => {
      streamChatResponse(this.state.community, {
        prompts: this.state.newPrompts,
        session_id: this.state.sessionId,
        requires_confirmation: this.state.aiConfirmationType !== null
      }, 'suggest-content', this.updateChatMessage)
      this.scrollToBottom()
    })
  }

  private executeDefaultPrompt = (prompt: Prompt) => {
    // if prompt has some placeholder it means it requires user's input
    if (prompt.placeholder) {
      this.setState({
        prompts:
          [
            ...this.state.prompts,
            {
              role: prompt.role,
              content: prompt.content,
            }
          ],
        newPrompts: [
          ...this.state.newPrompts,
          {
            role: prompt.role,
            content: prompt.content,
          }
        ],
        chatInputPlaceholder: prompt.placeholder,
        showDefaultPrompt: false,
      })
      this.chatInputRef.current.focus()
    } else {
      this.setState({
        showDefaultPrompt: false,
      })
      this.getSources()
    }
  }

  private getSources = () => {
    new Dataset()
      .loadCommunityDataset('communitySources', this.state.community)
      .then((response) => {
        if (!Array.isArray(response[0])) {
          return
        }
        const sortedObjects = [...response[0]].sort((a, b) => b.last_week_total - a.last_week_total)
        const topTwoObjects: any = sortedObjects.slice(0, 2)
        const sourceUrlsString = topTwoObjects
          .flatMap(obj => obj.source_url.split(',').map(url => url.trim()))
          .join('\n')
        this.sendMessage(`${DEFAULT_PROMPTS[0].content}\n${sourceUrlsString}`)
      })
  }


  private updateChatMessage: GenerativeAI.ChatCallback = (response: GenerativeAI.ChatResponse) => {
    if (!response) {
      this.setState({
        isSaving: false,
        newPrompts: [],
      })
    } else {
      if (response.done || response.error) {
        if (response.session_id) {
          this.setState({
            sessionId: response.session_id,
          })
        }
        let jsonString = response.message
        let aiJsonResponse = tryParseJSON(response.message)
        if (Object.keys(aiJsonResponse).length || this.state.aiJsonResponse) {
          if (Object.keys(aiJsonResponse).length === 0) {
            this.streamJson(response.message)
            jsonString = this.state.aiJsonResponse + response.message
            aiJsonResponse = tryParseJSON(jsonString)
          }
          if (aiJsonResponse && Object.keys(aiJsonResponse).length > 0) {
            if (isTruthy(aiJsonResponse.confirm) && this.state.aiConfirmationType !== null) {
              this.takeConfirmationAction(aiJsonResponse.confirm)
              return
            }
            if (aiJsonResponse.suggestedSources && aiJsonResponse.suggestedSources.length > 0) {
              this.setState({
                aiConfirmationType: Constants.AIConfirmationType.SOURCE_ADD,
                suggestedSources: aiJsonResponse.suggestedSources,
                showsuggestedSourcesStream: false,
                prompts: [
                  ...this.state.prompts,
                  {
                    role: Constants.PROMPT_ROLE.ASSISTANT,
                    content: jsonString,
                  }
                ],
                aiJsonResponse: '',
                showStreamLoader: false,
              })
            } else if (aiJsonResponse.source) {
              this.updateSourcesResponse(response)
            } else if (aiJsonResponse.summary) {
              let promptVal = [
                ...this.state.prompts,
                {
                  role: Constants.PROMPT_ROLE.ASSISTANT,
                  content: jsonString,
                }
              ]
              if (this.state.getSource) {
                promptVal = [
                  ...this.state.prompts
                ]
              }
              this.setState({
                prompts: promptVal,
                aiJsonResponse: '',
                aiConfirmationType: Constants.AIConfirmationType.SOURCE_SUGGESTION_REQUEST,
                summary: aiJsonResponse.summary,
                topics: aiJsonResponse.topics,
                showsummaryStream: false,
                showStreamLoader: false,
              }, () => {
                this.scrollToBottom()
                this.streamSummaryConfirmation()
              })
            } else if (aiJsonResponse.prompt) {
                this.setState({
                  prompts: [
                    ...this.state.prompts,
                    {
                      role: Constants.PROMPT_ROLE.ASSISTANT,
                      content: aiJsonResponse.prompt,
                    }
                  ],
                  aiJsonResponse: '',
                  showpromptStream: false,
                  showStreamLoader: false,
                })
              } else {
                this.setState({
                  aiJsonResponse: '',
                  showStreamLoader: false,
                })
                this.streamText(Constants.REX_COULD_NOT_FIND_MSG)
              }
            } else {
              this.setState({
                aiJsonResponse: '',
                showStreamLoader: false,
              })
              this.streamText(Constants.REX_COULD_NOT_FIND_MSG)
            }
        } else {
          this.setState({
            prompts: [
              ...this.state.prompts,
              {
                role: Constants.PROMPT_ROLE.ASSISTANT,
                content: this.state.prompt + response.message,
              }
            ],
            showStreamLoader: false,
          })
        }
        this.setState({
          isSaving: false,
          error: response.error,
          prompt: '',
          newPrompts: [],
        })
      } else {
        if (response.message) {
          const aiJsonResponse = tryParseJSON(response.message)
          if (aiJsonResponse.source) {
            this.updateSourcesResponse(response)
          } else if (trim(response.message).startsWith('{') || this.state.aiJsonResponse) {
            this.streamJson(response.message)
            this.setState({
              aiJsonResponse: this.state.aiJsonResponse + response.message,
              showStreamLoader: false,
            })
          } else {
            this.setState({
              prompt: this.state.prompt + response.message,
              showStreamLoader: false,
            })
          }
        }
      }
      this.scrollToBottom()
    }
  }

  private updateSourcesResponse: GenerativeAI.ChatCallback = (response: GenerativeAI.ChatResponse) => {
    if ( !response ) {
      this.setState({
        isSaving: false,
        showDefaultPrompt: false,
        showStreamLoader: false,
        chatInputPlaceholder: '',
        aiConfirmationType: null,
      })
    } else {
      const sourceJson = tryParseJSON(response.message)
      if (Object.keys(sourceJson).length) {
        if (response.done) {
          const sources = [
            ...this.state.sources,
            sourceJson.source,
          ]
          this.setState({
            prompts: [
              ...this.state.prompts,
              {
                role: Constants.PROMPT_ROLE.ASSISTANT,
                content: JSON.stringify({
                  sources
                }),
              },
            ],
            sources: [],
          }, () => {
            this.scrollToBottom()
          })
        } else {
          this.setState({
            sources: [
              ...this.state.sources,
              sourceJson.source,
            ],
            isSaving: false,
            showStreamLoader: false,
          }, () => {
            this.scrollToBottom()
          })
        }
      } else {
        this.updateChatMessage(response)
      }
      this.scrollToBottom()
    }
  }

  private moveContentToSource = (payload: any) => {
    this.context.store.dispatch(GA.sendGa4Event(this.ga4Event(GA.EventNames.TRexAddSource)))
    this.setState({
      isLoading: true,
      showStreamLoader: true,
    })

    const processEntity = (data: any) => {
      return this.context.entityMetadata.getEntityObject('communitySource').then((entity) => {
        entity.setRecordId(this.state.activeCommunity.communityId, null)
        entity.data.name = data.name
        entity.data.community_id = this.state.activeCommunity.communityId
        entity.data.identifier = data.source_url
        entity.data.subscription_type_id = SubscriptionTypes.normal
        entity.data.type = data.source_type
        return entity.save()
      })
    }

    const processPayload = () => {
      if (Array.isArray(payload)) {
        return Promise.all(payload.map((item) => processEntity(item)))
      } else {
        return processEntity(payload)
      }
    }

    return processPayload()
      .then(() => {
        this.setState({
          isLoading: false,
          suggestedSources: [],
          aiConfirmationType: null,
          showStreamLoader: false,
        })
        this.streamText('Source has been successfully added to your sources.')
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          showStreamLoader: false,
        })
      })
  }


  private getChatSessions = () => {
    return new Dataset().loadCommunityDataset('aiChatSessions', this.state.community, []).then((aiChatSessions) => {
        if (aiChatSessions[0].length) {
          this.setState({
            aiChatSessions: aiChatSessions[0],
          })
        }
      })
  }

  private getChatHistory = (sessionId) => {
    this.setState({
      sessionId,
      isChatHistoryLoading: true,
    })
    return new Dataset().loadCommunityDataset('aiChatHistory', this.state.community, [{param: 'sessionId', value: sessionId}]).then((aiChatHistory) => {
      if (aiChatHistory[0].length) {
        const prompts = aiChatHistory[0].filter((prompt) => prompt.source !== Constants.CHATSOURCE.FULL_SOURCES_LIST &&
          (prompt.source === Constants.CHATSOURCE.RECOMMENDATION_ENGINE || JSON.parse(prompt.options).role !== Constants.PROMPT_ROLE.SYSTEM)).map((prompt)=> {
          const jsonContent = tryParseJSON(prompt.prompt)
          let content = jsonContent.prompt ? jsonContent.prompt : prompt.prompt
          const options = tryParseJSON(prompt.options)
          if (prompt.source === Constants.CHATSOURCE.RECOMMENDATION_ENGINE) {
            const sources = tryParseJSON(prompt.prompt)
            content = JSON.stringify({
              sources,
            })

          }
          return {
            role: options.role || Constants.PROMPT_ROLE.ASSISTANT,
            content,
          }
        })
        this.setState({
          isChatHistoryLoading: false,
          prompts,
        }, () => {
          this.scrollToBottom()
        })
      }
    }).catch(() => {
      this.setState({
        isChatHistoryLoading: false,
      })
      this.context.store.dispatch(Flash.showFlashMessage('Unable to fetch chat history. Please try again.'))
    })
  }

  private takeConfirmationAction = (confirm) => {
    if (!isTruthy(confirm)) {
      return
    }
    this.setState({
      prompt: '',
    })
    if (this.state.aiConfirmationType === Constants.AIConfirmationType.SOURCE_ADD) {
      this.moveContentToSource(this.state.suggestedSources)
    } else if (this.state.aiConfirmationType === Constants.AIConfirmationType.SOURCE_SUGGESTION_REQUEST) {
      this.suggestContent()
    }
  }

  private streamJson = (jsonStream: string) => {
    jsonStream = jsonStream
    const pickTriggers = [
      "{",
      " {",
      " { ",
      "{\n",
      "{\"",
      ".\",",
      "?\",",
      "?\",\n",
      ".\",\n",
    ]
    const ignoreStreamData = [
      ...pickTriggers,
      " ",
      '"',
      "",
      "\n",
      "\":",
      '":',
      '"}',
      "\"}",
      " \"",
      ' "',
    ]
    const replaceStreamData = [{
      key: "?\",",
      value: "?",
    }, {
      key: "?\",\n",
      value: "?",
    }]
    if (pickTriggers.includes(jsonStream)) {
      this.setState({
        pickStreamKey: true,
      })
    } else if (ignoreStreamData.includes(jsonStream)) {
      return
    } else {
      if (this.state.pickStreamKey) {
        this.setState({
          ...this.state,
          [`show${this.state.pickStreamKey}Stream`]: true,
          currentStreamKey: jsonStream,
          pickStreamKey: false,
        })
      } else {
        if (this.state.currentStreamKey !== 'prompt' || this.state.aiConfirmationType === null) {
          // replace stream Data
          replaceStreamData.forEach((replaceData) => {
            jsonStream = jsonStream.replace(replaceData.key, replaceData.value)
          })
          this.setState({
            ...this.state,
            showStreamLoader: false,
            [this.state.currentStreamKey]: this.state[this.state.currentStreamKey] + jsonStream,
          }, () => {
            this.scrollToBottom()
          })
        }
      }
    }
  }

  private delay = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  private streamSummaryConfirmation = () => {
    if (this.state.getSource) {
      this.scrollToBottom()
      this.suggestContent()
    } else {
      this.streamText(FETCHING_SOURCE, true).then(() => {
        this.scrollToBottom()
        this.suggestContent()
      })
    }
  }

  private convertTextToLinks = (text: string): string => {
    // Regex pattern to match URLs
    const urlPattern = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*)/gi;
    // Regex pattern to match emails
    const emailPattern = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/gi;

    // Convert URLs to hyperlinks
    let result = text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    // Convert emails to mailto links
    result = result.replace(emailPattern, '<a href="mailto:$1">$1</a>');

    return result;
  }


  private streamText =  (text, addToPrompt = true) => {
    const TEXT_STREAM_DELAY = 100
    const words = text.split(' ')
    this.logChatHistory(text)
    return new Promise((resolve, reject) => {
      return words.forEach((word, index) => {
        this.delay(TEXT_STREAM_DELAY * (index + 1)).then(() => {
          if (index === words.length - 1) {
            resolve(true)
            if (addToPrompt) {
              this.setState({
                prompt: '',
                prompts: [
                  ...this.state.prompts,
                  {
                    role: Constants.PROMPT_ROLE.ASSISTANT,
                    content: `${this.state.prompt} ${word}`,
                  }
                ],
              }, () => {
                this.scrollToBottom()
              })
            } else {
              this.setState({
                prompt: `${this.state.prompt} ${word}`,
              }, () => {
                this.scrollToBottom()
              })
            }
          } else {
            this.setState({
              prompt: `${this.state.prompt} ${word}`,
            }, () => {
              this.scrollToBottom()
            })
          }
        })
      })
    })
  }
  private logChatHistory = (message, role = Constants.PROMPT_ROLE.ASSISTANT) => {
    // This is a fire and forget method we don't care if this fails.
    const url: string = `${AjaxWrapper.getServerUrl()}/ai/${this.state.community}/log-chat-history`
    return AjaxWrapper.ajax(url, HttpMethod.POST, {
      session_id: this.state.sessionId,
      prompt: message,
      role,
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Error logging chat history', error)
    })
  }
  private clearChat = () => {
    this.setState({
      ...newState,
      aiChatSessions: this.state.aiChatSessions,
      activeCommunity: this.state.activeCommunity,
      community: this.state.community,
      model: this.state.model,
      getSource: false
    }, () => {
      this.getChatSessions()
    })
  }

  private ga4Event = (eventName: string): GA.Event4 => {
    return {
      name: eventName,
      payload: {
        source: 't-rex-chat',
      },
    }
  }
}

