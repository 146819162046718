export const REDUX_STORE_HOME = 'add_suggested_source'
export const SOURCE_NAME = 'SuggestedSource'
export const SUGGEST_SOURCES_PAGE = 'suggest-sources'
export const SUGGEST_ARTICLES_PAGE = 'suggest-articles'
export const SUGGEST_CONTENT_PAGE = 'suggest-content'
export const REX_COULD_NOT_FIND_MSG = "I'm sorry, but I couldn't find what you were looking for. Can you provide more context so I can better assist you?"
export const SHOW_MORE_SOURCES_PROMPT = 'Show more sources'
export const ADD_ALL_SOURCES_PROMPT = 'Add all sources to my source list'
export const FETCHING_SOURCE  = 'Hold tight, I\'m fetching some stuff for you based on the given summary & topics...'

export interface Prompt {
  role: string,
  content: string,
  placeholder?: string,
}

export enum AIConfirmationType {
  SOURCE_ADD = 'SOURCE_ADD',
  SOURCE_SUGGESTION_REQUEST = 'SOURCE_SUGGESTION_REQUEST',
}

export enum CHATSOURCE {
  AI_ENGINE = 'AIEngine',
  RECOMMENDATION_ENGINE = 'RecommendationEngine',
  FULL_SOURCES_LIST = 'FullSources',
  RASA_RESPONSE = 'RasaResponse',
}

export enum PROMPT_ROLE {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant',
}

export const EXPLORE_MORE_PROMPT: Prompt = {
  role: "user",
  content: "Not satisfied with the given summary & topics. Want to explore more."
}

export const DEFAULT_PROMPTS: Prompt[] = [
  {
    role: "user",
    content: 'Suggest sources that are relevant to my newsletter',
  },
  {
    role: "user",
    content: "Suggest sources that are similar to the source or URL provided below",
  }
]
